import * as endpoints from 'services/component-template/endpoints';
import * as api from 'utils/axios';
// _types
import { ComponentTemplateType } from '_types/component';

export const getComponentTemplate = () =>
  api.sendGet(endpoints.GET_COMPONENT_TEMPLATE, {
    headers: { 'Content-Type': 'application/json', accept: 'application/json' },
    data: {},
  });

export const createComponentTemplate = (params: ComponentTemplateType) =>
  api.sendPost(endpoints.CREATE_COMPONENT_TEMPLATE, params);

export const getComponentTemplateDetail = (templateId: string) =>
  api.sendGet(`${endpoints.GET_COMPONENT_TEMPLATE}/${templateId}`, {
    headers: { 'Content-Type': 'application/json', accept: 'application/json' },
    data: {},
  });

export const updateComponentTemplate = (request: {
  componentTemplateId: string;
  params: ComponentTemplateType;
}) =>
  api.sendPut(
    `${endpoints.UPDATE_COMPONENT_TEMPLATE}/${request.componentTemplateId}`,
    request.params,
    {
      headers: { 'Content-Type': 'application/json' },
    }
  );

export const deleteComponentTemplate = (templateId: string) =>
  api.sendDelete(`${endpoints.DELETE_COMPONENT_TEMPLATE}/${templateId}`, {
    headers: { 'Content-Type': 'application/json' },
    data: {
      templateId,
    },
  });

export const getComponentTemplateBySystem = (code: string) =>
  api.sendGet(endpoints.GET_COMPONENT_TEMPLATE_BY_SYSTEM + `?systemCode=${code}`, {
    headers: { 'Content-Type': 'application/json', accept: 'application/json' },
    data: {},
  });
